

export class Assistant {
  id = null
  picture = null
  assistantId = null
  object = 'assistant'
  createdAt = null
  name = null
  engineId = 1
  apiKey = null
  apiKeySource = Assistant.API_KEY_SOURCE_DEFAULT
  description = null
  model = 'gpt-4o'
  instructions = null
  fileIds = []
  metadata = null
  files = []
  filesToStore = []
  fileIdsToRemove = []
  communicationTones = []
  communicationObjective = null
  welcomeMessage = null
  initConversationMessages = []
  isActiveWebBrowsing = false
  uniqueId = ''

  static API_KEY_SOURCE_DEFAULT = 1
  static API_KEY_SOURCE_CUSTOM = 2

  constructor (data = null) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data) {
    this.id = data.id
    this.picture = data.picture
    this.assistantId = data.assistant_id
    this.communicationTones = data.communication_tones
    this.communicationObjective = data.communication_objective
    this.welcomeMessage = data.welcome_message
    this.createdAt = data.created_at
    this.name = data.name
    this.engine = data.engine
    this.apiKey = data.api_key
    this.description = data.description
    this.model = data.model
    this.instructions = data.instructions
    this.fileIds = data.file_ids
    this.metadata = data.metadata
    this.files = data.files
    this.filesToStore = []
    this.initConversationMessages = data.init_conversation_messages ? JSON.parse(data.init_conversation_messages) : []
    this.isActiveWebBrowsing = data.is_active_web_browsing
    this.uniqueId = data.unique_id
    this.apiKeySource = Assistant.API_KEY_SOURCE_DEFAULT
    this.engineId = data.engine_id
  }
}
